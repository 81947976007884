<template>
  <div>
    <h4 class="text-center my-4">決済情報</h4>
    <template v-if="!enableFlag || stripeExpiration">
      <div class="d-flex flex-wrap justify-content-center my-3">
        <b-button
          class="d-flex flex-column justify-content-between m-2 p-0 w-100"
          style="max-width: 480px;"
          variant="light"
          @click="submitTrial"
          >
          <h4 class="bg-dark py-3 text-white w-100">無料トライアル<span class="text-warning m-3 small">現在のプラン</span></h4>
          <div class="d-flex flex-column h-100 justify-content-between p-4 mx-auto w-100">
            <img src="../../assets/images/trial.png" class="w-50 mx-auto">
            <p class="mt-4">すべての機能が１月間無料で使えます。</p>
            <ul class="container list-group p-0">
              <li class="list-group-item text-success">すべての機能</li>
              <li class="list-group-item text-success">電話サポート</li>
              <li class="list-group-item text-success">メールサポート</li>
            </ul>
          </div>
        </b-button>
        <b-button
          class="d-flex flex-column justify-content-between m-2 p-0 w-100"
          style="max-width: 480px;"
          variant="light"
          v-b-modal="`checkout-modal`"
          v-if="enableFlag"
          >
          <h4 class="bg-dark py-3 text-white w-100">年額プラン</h4>
          <div class="d-flex flex-column h-100 justify-content-between p-4 mx-auto w-100">
            <img src="../../assets/images/checkout.png" class="w-50 mx-auto">
            <p class="mt-4">操作質問、過去の作図データも保管できます。</p>
            <p>1年間たったの年額30,000円（税抜）で利用できます。</p>
            <ul class="container list-group p-0">
              <li class="list-group-item text-success">すべての機能</li>
              <li class="list-group-item text-success">電話サポート</li>
              <li class="list-group-item text-success">メールサポート</li>
            </ul>
          </div>
        </b-button>
      </div>
    </template>
    <template v-else>
      <div class="text-center">
        <div
          class="d-flex flex-column justify-content-between mx-auto p-0 w-100"
          style="max-width: 480px;"
          >
          <h4 class="bg-success py-3 text-white w-100">年額プラン ご利用中</h4>
          <div class="d-flex flex-column h-100 justify-content-between p-4 mx-auto w-100">
            <img src="../../assets/images/calendar.png" class="w-50 mx-auto">
            <p class="mt-4">有効期限：{{ stripeExpireDate.substring(0, 10) }}</p>
            <p>次回決済日：{{ nextPaymentDate }}</p>
            <!-- TODO: 自動更新ボタンはひとまず非表示 -->
            <div v-show="false">
              <b-form-checkbox v-model="autoPayment" name="check-button" switch size="lg">自動更新</b-form-checkbox>
              <template v-if="autoPayment">
                <p class="mt-3">有効期限後、自動でプラン更新が行われます。</p>
              </template>
              <template v-else>
                <p class="mt-3">有効期限後、無料プランに切り替わります。</p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <b-modal class="modal-notice-text px-3" hide-footer id="checkout-modal" ref="card-input" size="md" title="クレジットカード情報入力" @shown="onModalShown">
      <div id="payment-form">
        <table class="table table-bordered">
          <tr>
            <th class="align-middle">お支払金額（年額）</th>
            <td><span style="color: #F44; font-size: 1.5em; margin-right: 0.25em;">33,000</span>円（内消費税3,000円）</td>
          </tr>
        </table>
        <div id="card-container"></div>
        <p style="font-size: 0.88em;">以上の内容でよろしければ「支払確定」ボタンをクリックしてください。</p>
        <div id="payment-status-container" style="margin-bottom: 1em;"></div>
        <div class="text-center">
          <button class="btn btn-primary" id="card-button" type="button" @click="paymentMethodSubmission">支払確定</button>
        </div>
      </div>
    </b-modal>
    <b-modal class="modal-notice-text px-3" ok-only ref="payment-success" size="md" title="決済完了" @ok="onModalOK">
      <p>決済が完了しました。</p>
    </b-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      payments: null,
      card: null,
      applicationId: process.env.VUE_APP_APPLICATION_ID,
      locationId: process.env.VUE_APP_LOCATION_ID,
      token: null,
      verificationToken: null,
      user: null,
      stripeExpiration: true,
      stripeExpireDate: null,
      autoPayment: true,
      enableFlag: false
    }
  },
  mounted () {
    this.user = this.$store.getters['user/user']
    const username = this.$store.getters['user/user'].username
    const url = `/api/account/stripeExpiration/${username}`
    this.axios.post(url).then(res => {
      this.stripeExpiration = res.data.isExpiration
      this.stripeExpireDate = res.data.expireDate
      this.enableFlag = res.data.enableFlag
      this.autoPayment = this.user.auto_payment
      this.$watch('autoPayment', function (val) {
        const username = this.$store.getters['user/user'].username
        const url = `/api/account/autoPayment/${username}/${val}`
        this.axios.post(url).then(res => {
          if (val) {
            alert('自動更新をオンにしました。')
          } else {
            alert('自動更新をオフにしました。')
          }
        })
      })
    })
    window.onload = () => {
      if (!window.Square) {
        throw new Error('Square.js failed to load properly')
      }

      try {
        this.payments = window.Square.payments(this.applicationId, this.locationId)
      } catch {
        const statusContainer = document.getElementById(
          'payment-status-container'
        )
        statusContainer.className = 'missing-credentials'
        statusContainer.style.visibility = 'visible'
      }
    }
  },
  computed: {
    nextPaymentDate () {
      const date = new Date(this.stripeExpireDate)
      date.setDate(date.getDate() + 1) // 翌日を設定
      return date.toISOString().substring(0, 10)
    }
  },
  methods: {
    onModalShown () {
      try {
        if (this.enableFlag) {
          this.initializeCard()
        }
      } catch (e) {
        console.error('Initializing Card failed', e)
      }
    },
    submitTrial () {
    },
    async paymentMethodSubmission () {
      const cardButton = document.getElementById('card-button')

      try {
        // disable the submit button as we await tokenization and make a payment request.
        cardButton.disabled = true
        this.token = await this.tokenize()
        const paymentResults = await this.createPayment()
        if (paymentResults.payment_id) {
          this.displayPaymentResults('SUCCESS')
        } else {
          cardButton.disabled = false
          this.displayPaymentResults('FAILURE:' + paymentResults.message)
        }
      } catch (e) {
        cardButton.disabled = false
        this.displayPaymentResults('FAILURE')
      }
    },
    async initializeCard () {
      this.card = await this.payments.card()
      await this.card.attach('#card-container')
    },
    async createPayment () {
      const body = JSON.stringify({
        locationId: this.locationId,
        sourceId: this.token,
        userMail: this.user.email
      })

      const paymentResponse = await fetch('/api/account/payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body
      })

      if (paymentResponse.ok) {
        return paymentResponse.json()
      }

      const errorBody = await paymentResponse.text()
      throw new Error(errorBody)
    },
    async tokenize () {
      const tokenResult = await this.card.tokenize()
      if (tokenResult.status === 'OK') {
        return tokenResult.token
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`
        }
        throw new Error(errorMessage)
      }
    },
    // status is either SUCCESS or FAILURE
    displayPaymentResults (status) {
      const statusContainer = document.getElementById(
        'payment-status-container'
      )
      if (status === 'SUCCESS') {
        statusContainer.innerHTML = '決済に成功しました。'
        statusContainer.classList.remove('text-danger')
        statusContainer.classList.add('text-success')
        this.$refs['card-input'].hide()
        this.$refs['payment-success'].show()
        this.stripeExpiration = false
      } else {
        statusContainer.innerHTML = '決済に失敗しました。'
        statusContainer.classList.remove('text-success')
        statusContainer.classList.add('text-danger')
        console.log(status)
      }

      statusContainer.style.visibility = 'visible'
    },
    onModalOK () {
      window.location.reload()
    }
  }
}
</script>

<style scoped>

</style>
