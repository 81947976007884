<template>
  <div>
    <!-- TODO: Stripe HOLD
    <stripe-checkout
      ref="checkoutRef"
      mode="subscription"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="v => loading = v"
    />-->
    <h4 class="text-center my-4">料金プラン</h4>
    <div class="d-flex flex-wrap justify-content-center my-3">
      <b-button
        class="d-flex flex-column justify-content-between m-2 p-0 w-100"
        style="max-width: 480px;"
        variant="light"
        @click="submitTrial"
        >
        <h4 class="bg-dark py-3 text-white w-100">無料トライアル<span class="text-warning m-3 small">おすすめ！</span></h4>
        <div class="d-flex flex-column h-100 justify-content-between p-4 mx-auto w-100">
          <img src="../../assets/images/trial.png" class="w-50 mx-auto">
          <p class="mt-4">すべての機能が１月間無料で使えます。</p>
          <ul class="container list-group p-0">
            <li class="list-group-item text-success">すべての機能</li>
            <li class="list-group-item text-success">電話サポート</li>
            <li class="list-group-item text-success">メールサポート</li>
          </ul>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
// TODO: Stripe HOLD
// import { StripeCheckout } from '@vue-stripe/vue-stripe'
export default {
  components: {
    // StripeCheckout
  },
  data () {
    // this.publishableKey = ''
    return {
      // loading: false,
      // lineItems: [
      //   {
      //     price: 'price_1LUG5CJLuL9iDmX9sFVSc7ys',
      //     quantity: 1
      //   }
      // ],
      // successURL: 'http://127.0.0.1:8000/success?session_id={CHECKOUT_SESSION_ID}',
      // cancelURL: 'http://127.0.0.1:8000/menu'
    }
  },
  methods: {
    submit () {
      // this.successURL = `http://127.0.0.1:8000/success/${this.$route.params.id}/${this.$route.params.token}?session_id={CHECKOUT_SESSION_ID}`
      // this.$refs.checkoutRef.redirectToCheckout()
      this.redirectToSuccess()
    },
    submitTrial () {
      // this.successURL = `http://127.0.0.1:8000/success/${this.$route.params.id}/${this.$route.params.token}?session_id={CHECKOUT_SESSION_ID}`
      // this.lineItems = [
      //   {
      //     price: 'price_1LUHvuJLuL9iDmX9pm5pumfh',
      //     quantity: 1
      //   }
      // ]
      // this.$refs.checkoutRef.redirectToCheckout()
      this.redirectToSuccess()
    },
    redirectToSuccess () {
      location.href = `/success/${this.$route.params.id}/${this.$route.params.token}`
    }
  }
}
</script>

<style scoped>

</style>
