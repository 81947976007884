<template>
  <b-container>
    <h4 class="text-center my-4">認証コードの入力</h4>
    <b-alert show :variant="variant">
      <div v-if="confirmed">
        <div>
          認証コードが正しく確認できました！<br>
          つづけてログインへお進みください。
        </div>
        <div class="text-right">
          <b-button @click="go2login" variant="primary">ログインへ</b-button>
        </div>
      </div>
      <div v-else-if="error_message.length > 0">
        <div>{{ error_message }}</div>
        <div v-if="invalid_url">お送りしましたメールに記載のURLで、再度アクセスしなおしてください。</div>
      </div>
      <div v-else>
        新規登録いただき誠にありがとうございます。<br>
        お送りしましたメールに記載の【認証コード】をご入力ください。
      </div>
    </b-alert>

    <b-container style="width: 100%; max-width: 500px;" v-if="!invalid_url && !confirmed">
      <b-row>
        <b-col v-for="n in numbers.length" :key="n">
          <b-form-input ref="inputs" v-model="numbers[n - 1]" @keydown="nameKeydown($event)" @input="inputNumber(n - 1)" :autofocus="n === 1" />
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import constants from '../../constants'

export default {
  name: 'SignupConfirm',
  data: () => ({
    confirmed: false,
    numbers: ['', '', '', '', ''],
    error_message: '',
    invalid_url: false,
    isInputApproved: false
  }),
  computed: {
    variant () {
      if (this.error_message.length) {
        return 'danger'
      }

      return 'success'
    }
  },
  methods: {
    nameKeydown (event) {
      var key = event.keyCode
      if (!((key >= 48 && key <= 57) || (key >= 96 && key <= 105))) {
        return false
      }
      this.isInputApproved = true
    },
    inputNumber (index) {
      if (this.isInputApproved) {
        if (this.$refs.inputs[index + 1]) {
          this.$refs.inputs[index + 1].focus()
        } else {
          this.confirmNumber()
        }
      }
      this.isInputApproved = false
    },
    confirmNumber () {
      this.$emit('loading', true)

      const url = `/api/account/signup/${this.$route.params.id}/${this.$route.params.token}`
      this.axios.post(url, { code: this.numbers.join('') }).then(res => {
        this.confirmed = true
        this.error_message = ''
        this.$emit('loading', false)
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.$emit('loading', false)
          this.error_message = error.response.data.error
          this.invalid_url = error.response.data.invalid_url
        }
      })
    },
    go2login () {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped lang="scss">
input[type="text"] {
  text-align: center;
  font-size: 120%;
}
</style>
